const facts = [
  `Ivan Aleksijević Pančevac je za vreme snimanja klavira pio šljivovicu porodice Papić, hladnu kao taštija duša.`,
  `Najstarija pesma sa EPa ‘Zoe’ je Drvo.`,
  `Tadija je upoznao Anu Perišić na pomorskom kursu u Budimpešti.`,
  `Tingo nikada nije nastupao na Terra Festivalu u Kikindi.`,
  `Pingvini nemaju kolena.`,
  `Tingo je reč koja potiče sa Uskršnjih ostrva i opisuje čin pozajmljivanja stvari od prijatelja dok mu na kraju ništa ne ostane u kući.`,
  `ZOE je snimana u Coffee & Wine studiju na Bulbulderu; na Novom Beogradu - u Jazbini od Čarlija i Jekija i u stanu Telesković Tadije i Balabanović Marka, cimera mu; određeni vokalni kanali su doletali iz Utrehta, ali su ipak oni vračarski bili za par decibela bolji.`,
  `Nasuprot narodnom verovanju, električne slajd gitare na izdanju su snimane metalnim, a ne staklenim slajdom. Nošen je na malome prstu. Leve ruke. Ostali prsti su pomagali.`,
  `Hirohito u sebi skriva reprezentativan primer granularne modulacije.`,
  `Tadija u proseku kasni 7 minuta i 20 sekundi na snimanja glavnih vokala. Pomoćni vokali su druga prica.`,
  `Gurfa, Keekur, Ariojarakput i Marilopenatape su takođe reči specifične za određene kulture i geografska porekla i nemaju direktne prevode na ostale jezike.`,
  `Steely Dan, Stevie Wonder i Ray Charles su jedni od zajedničkih muzičkih heroja Tadije i Lazara.`,
  `Tadija gotivi Kolju i Grobovlasnike a Lazar Saleta i Sedlare – za srca su se međusobno ujeli. Ništa više nije bilo kao lani.`,
  `Preko 7 hiljada parova se rastalo posle prisustva na stendapu škotskog komičara Danijela Slosa.`,
  `Tadija i Lazar su se upoznali neposredno pred tehničku probu prvog beogradskog Sofar koncerta, u prostorijama Akademskog pozorišta Krsmanac u Balkanskoj 4, marta 2018. proleća gospodnjeg.`,
  `Mjanmar po popisu iz 2018. godine ima 53.71 miliona stanovnika. Što je dosta ljudi.`,
  `I Tadija i Lazar su svirali na nemačkim AIDA brodovima, u različitim vokalno-instrumentalnim sastavima.`,
  `Lazarov omiljeni broj je broj 4. Sada ga nosi Virdžil Van Dajk. Stivi Dži ga je nosio u reprezentaciji Engleske.`,
  `Marko Arizanović je jednom pitao nešto za druga, ali zapravo nije pitao ništa za druga. `,
  `Za većinu glavnih vokala na EPu ‘Zoe’ je korišćen Spirit, kondenzatorski mikrofon engleske firme Aston.`,
  `Pino Paladino je rođen u Kardifu 1957. godine od velš ke majke i italijanskog oca.`,
  `Autobus 55 nije promenio trasu otkako se Mina Teleskovic njime služi.`,
  `‘Iz pakla ljubavi spasila me droga’ poznate su reči Grunfa, jednog od junaka iz stripa Alan Ford – sa italijanskog jezika na srpsko-hrvatski preveo ih je Nenad Briksi.`,
  `Lazar je prvi razred osnovne škole zavrsio u Merrill Elementary školi  u Oškošu, gradiću u državi Viskonsin.`,
  `Lazarove gitare se zovu Kjara, Megi i Noa.`,
  `Mali mokri lug je, statistički gledano, prvi na svetu po veličini Mokri Lug.`,
  `Tadija radi u osnovnoj muzičkoj školi u Majdanpeku kao nastavnik gitare.`,
  `Međugradski autobusi su moderan metod mučenja.`,
  `Na putu Beograd - Majdanpek postoji dovoljno rupa da se uskladišti sav otpad sa deponije u Vinči.`,
  `Lazar je Tadiju navukao na poluslatko belo vino "Kaštel".`,
  `U studiju "Coffee & Wine" vazda ima neke domaćinske rakije.`,
  `Lazarova mama je u ključnim momentima svojim veštim kulinarskim sposobnostima spasila Tadiju od sigurne smrti od gladi tačno 77 puta.`,
  `Gasovi u svom prirodnom obliku nemaju nikakav miris.`,
  `Zoe je obožavala plazma keks.`,
  `Zoe je iza sebe ostavila srebrnog letača Ozija.`,
  `Ozi se preziva "Pawsbourne".`,
  `Zoe je imala nadimke: Zorka, Ćurka, Perka, debela, buba, dupe, oko i još nebrojeno mnogo.`,
  `Ozi je tako strastveni hvatač loptica da je hvatajući ih sebi isterao oba prednja zuba gornje vilice.`,
  `Nafta je neobnovljivi energetski resurs.`,
  `Rekonstrukcijom toplane u Majdanpeku, sa mazuta će se preći na pelet, time će se polucija vazduha smanjiti za tačno 0,07%, a grejna moć koincidentalno smanjiti za isto toliko.`,
  `Rudnik zlata u Majdanpeku je prodat Kinezima.`,
  `Tadija je izgubio nevinost sa 17 godina.`,
  `Lazar je u horoskopu devica a Tadija vaga.`,
  `Tadija ima položen vozački ispit, a Lazar nema. `,
  `Lazara će devojka osvojiti za tačno 38.7% efikasnije ako ima izrazito veliku glavu.`,
  `Tadija je osvojio 3. mesto na takmičenju u stonom tenisu do 14 godina pre sto godina.`,
  `Tadija je strastveni skijaroš. A voli da igra i basket.`,
  `U međusobnom susretima u šahu, Tadija beži Lazaru za jednu pobedu.`,
  `Tadija ima više od 15 hiljada osvojenih trofeja u sezoni Brawl Stars-a 2019/2020.`,
  `Tingovci su pripreme za prvi zajednički javni nastup u životu održavali na Belim Vodama.`,
  `Lazar ne ume da funkcioniše bez unapred rukom ispisanog nedeljnog rasporeda. Dalje planove kombinuje sa mesečnim na kompu.`,
  `Homo Sapiensi su jedine životinje koje imaju sposobnost da se zarumene.`,
  `Šumske zabe, na latinskom poznate i kao Rana Sylvatica, mogu da izdrže i do 8 meseci bez mokrenja.`,
  `Šećernu vunu je izmislio niko drugi no Vilijam Morison 1897. Bio je zubar.`,
  `Creedence Clearwater Revival je bend koji je imao najvise No.2 hitova u istoriji popularne muzike – nikada nisu imali hit na čelu liste.`,
  `Zvanična nacionalna životinja Škotske je jednorog.`,
  `Koalini otisci prstiju su iznenađujuće slični ljudskim.`,
  `Napoleon Bonaparte je jednom prilikom bio napadnut od strane hiljadu zečeva.`,
  `Tempo pesme ‘Da znaš’ se kreće oko 141 bpm-a.`,
  `Brzina ljudskog kijanja je veća od maksimalne brzine koju može da razvije gepard.`,
  `Plutonu je potrebno 248 zemaljskih godina da obrne pun krug oko Sunca. Njegov prvi rođendan će biti 23. Marta 2178.`,
  `Najlepši zalazak sunca Lazar je doživeo u Tusonu, u Arizoni. Bio je decembarski, pustinjski zalazak.`,
  `Šakil O’nil je jedinu trojku u životu ubacio 16. Februara 1996. u dresu Orlando Medžika protiv Milvoki Baksa.`,
  `Prva vinil ploca koju je Lazar samostalno nabavio bio je ‘Mingus Ah Um’ – drugi studijski album Čarlsa Mingusa. Bio je zaljubljen u izvođenje pesme ‘Goodbye Pork Pie Hat’.`,
  `Iako su bile male kvote za takav ishod, Tadija je ipak uspeo da Lazara navuče na dve video igre u poslednje tri godine – Rocket League i Brawl Stars.`,
  `Lazar je 2016. godine diplomirao prosekom 9.33. Bio je vukovac u osnovnoj. U gimnaziji je imao jednu četvorku iz nemačkog jezika u četvrtoj godini. Više puta je sanjao kako je opet u gimnaziji i kako ga proganja neznanje nemačkog. Šteta, pošto je Ksenija bila super profesorka i više ga je naučila o životu no o nemačkom.`,
  `Premijera debitantskog mjuzikla Sofije Nedeljković je zbog epidemiološke situacije pomerena za leto 2021.`,
  `Bez obzira što na Vikipediji piše drugacije, ‘Ti nisi njen, ti si moooooj’ je rečenica koju je izgovorio Zdravko Matić za vreme latentne proslave 25. rođendana Damjana Jovanovića.`,
  `Uroš Tomović je programerski džedaj. He always has the high ground. Osim u stonom tenisu. U stonom tenisu tek ponekada.`,
  `Lazar mahom koristi Curt Mangan žice za svoje električne gitare. Megi nosi stainless steel 10ke, a Kjara pure nickel 10ke.`,
  `U Poljskoj postoji 17553 crkava.`,
  `Lazarove omiljene žvake su Orbitove, od eukaliptusa.`,
  `Bob Rejnolds uglavnom koristi Mark 6 saksofon.`,
  `Tingovci imaju previše ideja za pesme, biće pakao strihovati setlistu za naredni dugometražni album.`,
  `Na pauzi između svirki, Tadija preferira da pije Ginger Fresh napitak ako je ikako moguće.`,
  `Na kruzeru je Tadija imao prilike da džemuje sa Františekom Ferencom Farkašom i Aleksandrom Lipovanom Šanjikom. Izabrali su numeru ‘Wave’ od Jobim-a.`,
  `Promocija debitantskog EP-ja ZOE desila se na sredu, 30. septembra u KC Gradu. Tadija je tog dana uš ao u 26. godinu svog života. Tog prepodneva je i diplomir’o.`,
  `Tadija je u avgustu 2010. nastupao na Guitar Art Summer Festivalu u Herceg Novom.`,
  `Lazar priznaje da Koljin album ‘Grobovlasnici‘ iz 2013. zapravo i nije toliko loš. Naprotiv.`,
  `Teško je pronaći kvalitetan himalajski tongue drum za manje od 200 dolara.`,
  `Lazarevi omiljeni gitaristi su Joey Landreth, Isaiah Sharkey, Wayne Krantz, Adam Agati, Oz Noy, Robben Ford, Matthew Stevens, David T. Walker i Mark Lettieri. Kakva ekipa, eh? Kad poraste, voleo bi da postane D’angelo ali šanse za to su ravne nuli.`,
  `Jednog nedeljnog popodneva u budućnosti, Lazar će napraviti plejlistu svih pesama koji se zovu ‘Mango’. Za sad taj spisak broji dve cele pesme.`,
  `Snagu vinila Lazar je prvi put doživeo kada je sa određenom džez pevačicom kod kuće preslušao maminu ploču – ‘Time’ grupe Time. Kakav album, sunce ti. Tad još uvek nije postojao Netflix u Srbiji, nit je bilo chill-a.`,
  `Stari Grci su verovali da postoji više formi ljubavi: filija, eros, agape, storge i ksenija.`,
  `Prvi koncert na kome je Lazar prisustvovao bili su Stonsi na Uscu 2007, vodio ga ćale.`,
  `Lazarova spiritualna životinja je koala.`,
  `Čovek može da sagori 150 kalorija ako sat vremena kontinualno udara glavom o zid. Manje bolna alternativna je šetnja psa 45 minuta.`,
  `U Švajcarskoj je ilegalno posedovati samo jedno morsko prase za ljubimca.`,
  `29. maj je zvaničan ‘Stavi svoj jastuk u frižider’ dan.`,
  `Pravo ime Boba Dilana je Robert Allen Zimmerman.`,
  `U Ajfelovom tornju postoji tačno 1710 stepenica.`,
  `2000. godine su članovi benda ABBA odbili 1 milijardu dolara koja im je ponuđena za realizaciju povratničke turneje.`,
  `Vinsent Van Gog je za svog života prodao jednu jedinu sliku.`,
  `Konj u sebi ima 205 kostiju.`,
  `Grožđe je fatalno za pse i mačke, čak i u najmanjim količinama. Izaziva naprasni prestanak rada bubrega.`,
  `Havajska pica je zapravo kanadski izum. Smislio ju je Sam Panopulos u ‘Satellite’ restoranu u Ontariu.`,
  `Svake godine Nizozemska pošalje Kanadi 20 hiljada lala, u znak zahvalnosti za pomoć pri oslobađanju od okupacije u Drugom svetskom ratu.`,
  `Japanci koriste više papira za produkciju Mangi no za pravljenje toalet papira.`,
  `Sierra Leone je najoblija država na svetu.`,
  `Uroš je skontao grešku kod fun fact-a broj 92, odmah je bila prepravljena.`,
];

export default facts;
