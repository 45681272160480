import React from "react";
import decak from "./img/decak.png";
import sunce from "./img/sunce.png";
import totem from "./img/totem.png";
import senka from "./img/senka.png";
import facts from "./facts";
import { ReactComponent as ArrowSvg } from "./img/arrow.svg";
import { ReactComponent as RefreshSvg } from "./img/refresh.svg";
import { ReactComponent as YoutubeSvg } from "./img/youtube.svg";
import { ReactComponent as FacebookSvg } from "./img/facebook.svg";
import { ReactComponent as EmailSvg } from "./img/email.svg";
import { ReactComponent as BandcampSvg } from "./img/bandcamp.svg";
import { ReactComponent as InstagramSvg } from "./img/instagram.svg";
import { ReactComponent as DeezerSvg } from "./img/deezer.svg";
import { ReactComponent as ItunesSvg } from "./img/itunes.svg";
import { ReactComponent as SpotifySvg } from "./img/spotify.svg";
import { ReactComponent as TidalSvg } from "./img/tidal.svg";
import logoImg from "./img/logo.png";
import zoeImg from "./img/zoe.png";
import cx from "classnames";
import "./App.css";

class App extends React.Component {
  state = {
    scrollY: 0,
    isFixedLogoVisible: false,
    randomNum: Math.floor(Math.random() * facts.length),
    sFacts: facts,
    videoIndex: 0,
    showTadija2: false,
  };

  sunceX;
  totemS;
  senkaX;
  decakX;
  rotate;

  videos = [
    "https://www.youtube.com/embed/aAeCCzlrFrk",
    "https://www.youtube.com/embed/qmXac74-HfM",
    "https://www.youtube.com/embed/5rHEwZYdVb8",
    "https://www.youtube.com/embed/98_JckxxHr4",
    "https://www.youtube.com/embed/4AvrEEeuDtA",
  ];

  social = [
    {
      icon: FacebookSvg,
      name: "Facebook",
      url: "https://www.facebook.com/tingo.bend/",
    },
    {
      icon: InstagramSvg,
      name: "Instagram",
      url: "https://www.instagram.com/tingo.music/",
    },
    {
      icon: YoutubeSvg,
      name: "Youtube",
      url:
        "https://www.youtube.com/channel/UCbMCkB9Wbjlk6Y9BC1L1cdA?view_as=subscriber",
    },
    {
      icon: BandcampSvg,
      name: "Bandcamp",
      url: "https://bendpoimenutingo.bandcamp.com/releases",
    },
    {
      icon: EmailSvg,
      name: "Email",
      url: "mailto:bendpoimenutingo@gmail.com",
    },
    {
      icon: DeezerSvg,
      name: "Deezer",
      url: "https://www.deezer.com/us/album/171934192",
    },
    {
      icon: ItunesSvg,
      name: "Itunes",
      url:
        "https://music.apple.com/us/album/zoe-ep/1530945208?at=1001lbRT&ct=604020",
    },
    {
      icon: SpotifySvg,
      name: "Spotify",
      url: "https://open.spotify.com/album/2v6SWELLCer5dSkca6CoM3",
    },
    {
      icon: TidalSvg,
      name: "Tidal",
      url: "https://listen.tidal.com/album/154564387",
    },
  ];

  titleRef = React.createRef();
  contentRef = React.createRef();
  tadija1Ref = React.createRef();
  tadija2Ref = React.createRef();

  componentDidMount() {
    document.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const titleRefTop = this.titleRef.current.offsetTop;

    this.setState({
      scrollY: window.pageYOffset,
      isFixedLogoVisible: titleRefTop - window.pageYOffset <= 20,
    });
  };

  handleButtonClick = () => {
    window.scrollTo({
      top: this.contentRef.current.offsetTop - 100,
      left: 0,
      behavior: "smooth",
    });
  };

  handleFactsClick = () => {
    const { randomNum, sFacts } = this.state;

    const index = sFacts.findIndex((item) => item === sFacts[randomNum]);

    sFacts.splice(index, 1);

    this.setState({
      randomNum: Math.floor(Math.random() * sFacts.length),
    });
  };

  handleVideoArrowClick = (dir) => {
    const { videoIndex } = this.state;

    if (dir === "prev") {
      this.setState({
        videoIndex: videoIndex - 1,
      });
    } else {
      this.setState({
        videoIndex: videoIndex + 1,
      });
    }
  };

  handleTadijaClick = () => {
    const { showTadija2 } = this.state;
    this.setState(
      {
        showTadija2: !showTadija2,
      },
      () => {
        const top = showTadija2
          ? this.tadija1Ref.current.offsetTop
          : this.tadija2Ref.current.offsetTop;
        window.scrollTo({
          top: top + window.innerHeight - 100,
          left: 0,
          behavior: "smooth",
        });
      }
    );
  };

  render() {
    const {
      scrollY,
      isFixedLogoVisible,
      randomNum,
      sFacts,
      videoIndex,
      showTadija2,
    } = this.state;

    const videoX = -100 * videoIndex;

    const buttonClasses = cx("Scroll-button", {
      "Scroll-button--hidden": scrollY > 50,
    });

    const titleClasses = cx("Title", { "Title--hidden": isFixedLogoVisible });

    const fixedTitleClasses = cx("Title--fixed", {
      "Title--hidden": !isFixedLogoVisible,
    });

    requestAnimationFrame(() => {
      this.sunceX = ((scrollY / window.innerHeight) * 100) / 4;

      this.totemS = 1 + scrollY / (window.innerHeight * 3);

      this.senkaX = this.sunceX * 3;
      this.decakX = this.sunceX * 3;
    });

    const leftArrowCx = cx({
      "Video-arrow--left": true,
      "Video-arrow--hidden": videoIndex === 0,
    });

    const rightArrowCx = cx({
      "Video-arrow--right": true,
      "Video-arrow--hidden": videoIndex === this.videos.length - 1,
    });

    const tadija2Cx = cx({
      Tadija2: true,
      "Tadija2--visible": showTadija2,
    });

    return (
      <div className="App">
        <div className="Cover-wrapper">
          <div className="Image-wrap">
            <img
              src={sunce}
              alt="item"
              style={{ transform: `translateX(-${this.sunceX}px)` }}
              className="Image-item Image-sunce"
            />
            <img
              src={senka}
              alt="item"
              style={{
                transform: `translate(${this.senkaX / 2}px, ${
                  this.senkaX * 0.9
                }px)`,
              }}
              className="Image-item Image-senka"
            />
            <img
              src={totem}
              alt="item"
              style={{
                transform: `scale(${this.totemS})`,
              }}
              className="Image-item Image-totem"
            />
            <img
              src={decak}
              alt="item"
              style={{
                transform: `translate(${this.decakX}px, ${
                  this.decakX * 0.9
                }px)`,
              }}
              className="Image-item Image-decak"
            />
          </div>
          <div className={titleClasses} ref={this.titleRef}>
            <img className="Logo" src={logoImg} alt="logo" />
          </div>
        </div>
        <div className={fixedTitleClasses}>
          <img className="Logo" src={logoImg} alt="logo" />
          <div className="Content-fader" />
        </div>
        <button onClick={this.handleButtonClick} className={buttonClasses}>
          <ArrowSvg />
        </button>
        <div className="Content" ref={this.contentRef}>
          <p>Zdravo stranče!</p>
          <br />
          <p>
            Mi smo Tadija i Lazar. <br /> Tingo je naš autorski muzički
            projekat.
          </p>
          <br />
          <p>
            <img src={zoeImg} className="Zoe" alt="tingo zoe" /> je naše
            debitantsko EP izdanje. <br /> Pet originalnih pesama, od treće
            nedelje septembra dostupne širom omiljenih mreža.
          </p>
          <br />
          <div className="Authors">
            <p ref={this.tadija1Ref}>
              Za dvanaesti rođendan su roditelji odlučili da daju šansu mojoj
              odgovornosti i kupili mi psa. <br /> <br />
              Dobio sam devojčicu patuljastog šnaucera koju smo nazvali{" "}
              <img src={zoeImg} className="Zoe" alt="tingo zoe" />.
              <br /> <br /> Kada sam je prvi put ugledao imala je mesec i po
              dana i tog momenta je postala neizostavni deo mog života. <br />{" "}
              <br />
              Ja sam nju učio da sedne i da beži sa lopticom kada je dohvati, a
              ona mene o ljubavi čistijoj od hladnog planinskog vazduha. Ta
              ljubav je danas nosilac moje ideje o životu, prijateljstvu, muzici
              i još verovatno mali milion stvari kojih ću tek postati svestan.
              <br /> <br />
              Tingo lično doživljavam kao jedno živo biće, koje upravo pravi
              svoje prve korake, a EP koji smo napravili je pokušaj izraza
              dečije poletnosti - po mnogo čemu naivan, ali sržanstveno iskren,
              kao jedan nov, neisprljan život.
              <br /> <br />
              Dozvolili smo si da se ne vodimo nekim normama, trendovima, i
              nismo se trudili da budemo naročito kul, što je, čini mi se, baš,
              onako... kul.
              {!showTadija2 && (
                <button
                  onClick={this.handleTadijaClick}
                  className="Authors-button"
                >
                  <ArrowSvg />
                </button>
              )}
            </p>
            <p className={tadija2Cx} ref={this.tadija2Ref}>
              <br />
              Pre nego što sam upoznao Lazara, sav moj muzički trud su,
              uglavnom, upijali zidovi studentskih soba. Zaista, to poznanstvo
              je neočekivano brzo postalo prijateljstvo, a jos brže i
              koristoljublje. Šalim se. Ali Lazar stvarno ima studio kojem imam
              pristup džabe i kad god poželim. A tu je i mama Olga koja pravi
              takvu klopu da... ipak je pomalo koristoljublje. Sve u svemu,
              sjajan tip a još bolji muž i otac. Opet se šalim, nije još stigao
              dotle.
              <br /> <br />
              Želja da tekstovi budu na srpskom jeziku je potekla od ideje o što
              preciznijem izražavanju, koliko god obojica poznavali engleski
              jezik, nikad ne bih ni pomislio da napišem pesmu koju neću moći da
              pustim svojoj babi koja ne zna reč tog istog jezika. Opet se malo
              šalim, ali ovaj put moj stav o ovome nije tako daleko od istine.
              <br /> <br />
              Ovde bih završio svoje izlaganje, treba polako da se tušnem,
              operem zube pa na spavanjac, neko ipak treba da piše diplomski rad
              izjutra. V!
              {showTadija2 && (
                <button
                  onClick={this.handleTadijaClick}
                  className="Authors-button Authors-button--top"
                >
                  <ArrowSvg />
                </button>
              )}
            </p>
            <br /> <br />
            <p className="Potpis">T</p>
            <br /> <br />
            <p>
              Pet pesama koje čine{" "}
              <img src={zoeImg} className="Zoe" alt="tingo zoe" /> su zaista u
              neku ruku pečat jednog dragog mi perioda – razvijanja jednog
              divnog prijateljstva i učenja o zajedničkom radu i muzičkom
              stvaralaštvu. Period realizacije ovog izdanja nikako nije bio
              kratak, ali ne žalim ni trenutka – svaki gram kreativnosti i
              ljubavi, kao i pažnja prema detaljima, doneo nam je prvenac kojim
              iskreno mislim da se možemo ponositi i tek je početak opusa lepih
              pesama benda po imenu Tingo.
              <br /> <br />U međuvremenu mi je Tadija postao jedan od omiljenih
              vokala na sceni, a rad sa njim me je inspirisao da postanem bolji
              dizajner zvuka no što sam ranije to bio, kao i gitarista.
              Stvaralački proces ponekada nije toliko jednostavan, pogotovo kada
              je vise njuški u igri, ali radna dinamika nam je prosta, direktna
              – dopunjavajuća; preživela je sve sitne (i očekivane) varnice,
              razigrala se i rašće. Tako mi Tutatisa.
              <br /> <br />
              Da je postavljeno pitanje – ’
              <img src={zoeImg} className="Zoe" alt="tingo zoe" />
              ?’, odgovor bi bio – ‘Dečaštvo, dobroćudnost, znatiželja, igra,
              prihvatanje.’
            </p>
            <br />
            <p className="Potpis">L</p>
            <br /> <br />
          </div>
        </div>
        <div className="Videos">
          <div className="Videos-controls">
            <button
              onClick={() => this.handleVideoArrowClick("prev")}
              className={leftArrowCx}
            >
              <ArrowSvg />
            </button>
            <button
              onClick={() => this.handleVideoArrowClick("next")}
              className={rightArrowCx}
            >
              <ArrowSvg />
            </button>
          </div>
          <div
            className="Videos-wrapper"
            style={{ transform: `translateX(${videoX}%)` }}
          >
            {this.videos.map((vid, index) => (
              <div key={index} className="Video">
                <iframe
                  title={`video ${index}`}
                  src={vid}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            ))}
          </div>
        </div>
        <div className="Social">
          {this.social.map((social) => {
            const Icon = social.icon;
            return (
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={social.url}
                key={social.name}
              >
                <Icon />
              </a>
            );
          })}
        </div>
        <div className="Facts">
          <h3 className="Facts-title">Tingo fun facts</h3>
          {sFacts.length > 0 ? (
            <p className="Facts-fact">
              {sFacts[randomNum]}{" "}
              <button onClick={this.handleFactsClick} className="Facts-button">
                <RefreshSvg />
              </button>
            </p>
          ) : (
            <p>Woah....sve si izlist'o. Nismo razmisljali ovoliko daleko.</p>
          )}
        </div>
      </div>
    );
  }
}

export default App;
